import apiClient from '@/services/api/clients/api-client'
import axios from 'axios'
import { fileUtils } from '@/services/file-utils.service'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/collection/packages`

const client = apiClient.build(path)

const search = options => {
  const url = `${path}/search`
  const { status, ...y } = options

  const params = new URLSearchParams(y)
  for (let i = 0; i < status.length; i++) {
    params.append('status', status[i])
  }

  return client.get(url, { params: params })
}

const downloadUrl = async id => {
  const url = `${configService.config.apiUrl}/collection/packages/${id}/media/downloadUrl`
  const response = await client.get(url)
  return response.data.url
}

const uploadUrl = async model => {
  const url = `${configService.config.apiUrl}/collection/packages/uploadUrl`
  const response = await client.__api.post(url, model)
  return response.data.uploadUrl
}

const upload = async (signedUrl, file) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  }

  return axios.put(signedUrl, file, options)
}

const uploadDirect = async (model, file) => {
  const uploadPath = `${configService.config.apiUrl}/collection/packages`

  const formData = new FormData()
  formData.append('collectionNumber', model.collectionNumber)
  formData.append('trackingId', model.trackingId)
  formData.append('file', file)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const process = async id => {
  const url = `${configService.config.apiUrl}/collection/packages/${id}/process`
  const response = await client.__api.put(url, {})
  return response.data
}

/*
Multipart upload
*/
const buildMultipartPath = id =>
  `${configService.config.apiUrl}/collection/packages/${id}/media/multipart`

const raiseEvent = (event, onProgress) => {
  if (typeof onProgress == 'function') {
    onProgress({ parts: 0, uploaded: 0, ...event })
  }
}

const uploadMultipart = async (request, file, options) => {
  const uploadRequest = {
    ...request,
    fileName: file.name,
    fileSize: file.size,
    contentType: file.type || 'application/zip'
  }

  if (file.size < fileUtils.minMultipartUploadPartSizeBytes) {
    console.log(
      'File is too small for multipart upload.  Uploading file directly'
    )
    return uploadDirect(uploadRequest, file)
  }

  return new Promise((resolve, reject) => {
    try {
      const { onProgress, onComplete, onStartMultipart } = options || {}
      raiseEvent({ msg: 'Starting multipart upload' }, onStartMultipart)

      const reader = new FileReader()

      reader.onload = async () => {
        try {
          const arrayBuffer = reader.result

          // 1. Create multipart upload
          raiseEvent({ msg: 'Creating multipart upload' }, onProgress)

          const { model, media } = await createMultipartUpload(uploadRequest)
          console.log('createResponse', { model, media })

          raiseEvent({ msg: 'Created multipart upload' }, onProgress)

          // 2. Upload parts
          const parts = fileUtils.splitArrayBuffer(
            arrayBuffer,
            fileUtils.minMultipartUploadPartSizeBytes
          )

          raiseEvent(
            { msg: 'Uploading parts', parts: parts.length },
            onProgress
          )

          for (const part of parts) {
            const uploadPartRequest = {
              id: media.id,
              partNumber: parts.indexOf(part) + 1,
              bytes: part
            }

            raiseEvent(
              {
                msg: `Uploading part ${uploadPartRequest.partNumber}`,
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber - 1
              },
              onProgress
            )

            await uploadPart(model.id, uploadPartRequest)

            raiseEvent(
              {
                msg: 'Part uploaded',
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber
              },
              onProgress
            )
          }

          // 3. Complete multipart upload
          const completeResponse = await completeMultipartUpload(model.id)

          raiseEvent(
            {
              msg: 'Completed multipart upload',
              parts: parts.length,
              uploaded: parts.length
            },
            onComplete
          )
          resolve({ model, media: completeResponse.data })
        } catch (error) {
          reject(error)
        }
      }

      reader.readAsArrayBuffer(file)
    } catch (error) {
      reject(error)
    }
  })
}

const createMultipartUpload = async request => {
  const multipartPath = `${configService.config.apiUrl}/collection/packages/media/multipart`
  return await client.__api
    .post(multipartPath, request)
    .then(response => response.data)
}

const uploadPart = (id, request) => {
  const multipartPath = buildMultipartPath(id)
  const uploadPath = `${multipartPath}/part/${request.partNumber}`
  const blob = new Blob([request.bytes], { type: 'application/octet-stream' })

  return client.__api
    .post(uploadPath, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
    .then(response => response.data)
}

const completeMultipartUpload = id => {
  const multipartPath = buildMultipartPath(id)
  const completePath = `${multipartPath}/complete`
  return client.__api.post(completePath).then(response => response.data)
}

const abortMultipartUpload = id => {
  const multipartPath = buildMultipartPath(id)
  const abortPath = `${multipartPath}/cancel`
  return client.__api.post(abortPath).then(response => response.data)
}

export default {
  fetch: client.fetch,
  search,
  downloadUrl,
  process,
  upload,
  uploadUrl,
  uploadDirect,
  uploadMultipart,

  createMultipartUpload,
  uploadPart,
  completeMultipartUpload,
  abortMultipartUpload
}
